import { createContext, useState, useEffect, useContext } from 'react';
import { auth } from '../firebase.config.js';
import { onAuthStateChanged } from 'firebase/auth';


const AuthStatusContext = createContext(null);

export const AuthStatusProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const [user, setUser] = useState(null);
    const [claims, setClaims] = useState(null);

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => {
            const doAsync = async () => {
                try {
                    if (user) {
                        setIsLoggedIn(true);
                        setUser(user);
                        const idTokenResults = await user.getIdTokenResult();
                        setClaims(idTokenResults.claims);
                    } else {
                        setIsLoggedIn(false);
                        setUser(null);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setCheckingStatus(false);
                }
            };
            doAsync();
        });
        return () => {
            unsub();
        };
    }, []);

    return (
        <AuthStatusContext.Provider
            value={{
                isLoggedIn,
                checkingStatus,
                user,
                claims,
            }}
        >
            {children}
        </AuthStatusContext.Provider>
    );

};

export const useAuthStatus = () => useContext(AuthStatusContext);

export default AuthStatusContext;