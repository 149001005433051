import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./firebase.config";

//Providers
import { AuthStatusProvider } from "./context/AuthContext";
import { GlobalProvider } from "./context/GlobalContext";

// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Page loader
import { WebPageLoader } from "./core-ui/Loaders";

import PrivateRoute from "./PrivateRoute";

//components
const NotFound = lazy(() => import("./pages/NotFound"));
const Countdown = lazy(() => import('./pages/Countdown/countdown'));
const PhotoGallery = lazy(() => import('./pages/PhotoGallery/'));
const Home = lazy(() => import('./pages/Home'));

function App() {
  return (
    <div className="App h-screen">
      <AuthStatusProvider>
        <Router>
          <GlobalProvider>
            <Suspense fallback={<div className="h-100vh"><WebPageLoader /></div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/countdown" element={<Countdown />} />
                <Route path="/gallery" element={<PhotoGallery />} />
                {/* 404 Not Found */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </GlobalProvider>
        </Router>
      </AuthStatusProvider>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
}

export default App;
