// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCr6hk9D_0kkztHNLP6wnNDs2H3n7vStvs",
  authDomain: "celebrating-julia.firebaseapp.com",
  projectId: "celebrating-julia",
  storageBucket: "celebrating-julia.appspot.com",
  messagingSenderId: "315257106213",
  appId: "1:315257106213:web:1ff3dfd4523c88bdf5f4cc",
  measurementId: "G-VYM7ME5YD1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage(app);