import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStatus } from './context/AuthContext';
import { FC } from 'react';

const PrivateRoute = ({
    children,
    shouldBeLoggedIn = true,
    redirectTo = 'login',
    auth = false,
    roles,
    status,
  }) => {
    const { claims, isLoggedIn, checkingStatus } = useAuthStatus()
  
    if (checkingStatus) return null;
    console.log(auth)
  
    if (!shouldBeLoggedIn && isLoggedIn && auth) {
      // Redirect to the admin dashboard if the user is already logged in
      return <Navigate to={redirectTo} replace />;
    }
  
    if (shouldBeLoggedIn && !isLoggedIn) {
      console.log('Redirecting to', redirectTo);
      return <Navigate to={redirectTo} replace />;
    }
  
    return children ? children : <Outlet />;
  };
  

export default PrivateRoute;
