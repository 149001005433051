import { CircleSpinner } from 'react-spinners-kit';

const ButtonLoaderDark = ({ className }) => {
    return (
        <div className={className}>
            <CircleSpinner color={'#bb86fc'} size={20} loading={true} />
        </div>
    );
};

export default ButtonLoaderDark;
