import React, { createContext, useContext, useReducer, ReactNode } from "react";
import globalReducer from "./GlobalReducer";


const GlobalContext = createContext(undefined);

export const GlobalProvider = ({ children }) => {
  // const queryURL = useQuery();

  const initialState = {
    pageTitle: "",
    backLink: "",
    userAvatar: "",
  };
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
};

export default GlobalContext;
