import { FlagSpinner } from 'react-spinners-kit';

const DataLoader = () => {
    return (
        <div>
            <FlagSpinner color={'#6f25ae'} size={40} loading={true} />
        </div>
    );
};

export default DataLoader;
